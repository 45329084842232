import apps from '../apps.json';
import { buildMode } from '../setupEnvironment';

export const SET_LOADING = 'true/applications/SET_LOADING';
export const OPEN_APPLICATION = 'true/applications/OPEN_APPLICATION';
export const CLOSE_APPLICATION = 'true/applications/CLOSE_APPLICATION';

const localState = (state) => state.applicationsState;
export const getLoading = (state) => localState(state).loading;
export const getApplications = (state) => localState(state).applications;
export const getOpenApplications = (state) => localState(state).openApplications;
export const isProd = (state) =>localState(state).isProd;

// Actions
export const openApplicationAction = (application) => ({
  type: OPEN_APPLICATION,
  application,
});

export const closeApplicationActionCreator = (application) => ({
  type: CLOSE_APPLICATION,
  application,
});

export const setLoadingAction = (loading) => ({
  type: SET_LOADING,
  loading,
});

export const Actions = {
  SET_LOADING,
};

export const initialState = {
  loading: true,
  isProd: buildMode === 'production',
  applications: apps,
  openApplications: [],
};

export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_APPLICATION:
      //  force only one application of each type to be opened at a time
      if (state.openApplications.includes(action.application)) return state;
      // eslint-disable-next-line no-case-declarations
      const openApplicationsWithNew = [...state.openApplications];
      openApplicationsWithNew.push(action.application);
      return {
        ...state,
        openApplications: openApplicationsWithNew,
      };
    case CLOSE_APPLICATION:
      // eslint-disable-next-line no-case-declarations
      const openApplicationsWithRemoved = [...state.openApplications];
      openApplicationsWithRemoved.splice(openApplicationsWithRemoved.indexOf(action.application), 1);
      return {
        ...state,
        openApplications: openApplicationsWithRemoved,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import ApolloClientConfig from './setupApollo';

import App from './App';

import './index.css';

const store = configureStore();

const client = ApolloClientConfig(store);

ReactDOM.render(
  <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);

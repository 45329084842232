const translations = {
  App_Not_Configured: {
    description: 'Message informing user that the application is not configured for the store',
    primaryValue: 'This app is not configured for the store, please reach out to store operations lead to get the app configured.',
    stringKey: 'appNotConfigured'
  },
  Click_Home_Button: {
    description: 'Message informing user to click the Home button to return to the landing page',
    primaryValue: 'Click the Home button to return to the landing page.',
    stringKey: 'clickHomeButton'
  },
  Before_Exit_Prompt_Message: {
    description: 'Message informing user that they are about to exit the application, and any unsaved data will be lost',
    primaryValue: 'Are you sure you want to exit? Any unsaved data will be lost.',
    stringKey: 'beforeExitPromptMessage'
  },
};

module.exports = translations;
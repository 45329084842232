import React from 'react';
import {
  string, bool, func, object,
} from 'prop-types';
import classNames from 'classnames';
import ApplicationImages from '../applications/images/index';
import LinkWithDisable from '../../components/linkWithDisable/LinkWithDisable';
import Banner from '../../components/banner/Banner';

import '../applications/Applications.css';
import ts from '../applications/applications.i18n';

import './AppCard.css';

/**
 * The Applications Page - lists all the applications available
 * @param {function} openApplication - handles opening the app in an iframe
 * @param {string} title - The name of the application
 * @param {string} image - The branded logo for an application
 * @param {string} slug - The route/url to an external app
 * @param {boolean} enabled - A value that lets us determine if a user
 * @param {object} authService - An object for authentication
 * @param {object} authState - An object for authentication state
 *
 * has the ability to access a certain app
 * @param {string} testId - The testId for an app
 */
const AppCard = ({
  openApplication, title, image, enabled, testId, slug, authService, authState, banner, i18nString, ...rest
}) => {
  const AppImg = ApplicationImages[image];

  const handleClick = () => {
    openApplication(testId);
  };

  const titleDiv = (title
    ? <div className={classNames('display-3 application-title', { 'application-title-disabled': !enabled })}>{title}</div>
    : <div className="loading-title" data-testid="application-loading-state-title" />);

  const applicationBody = (
    <div>
      {image
        ? 
          <AppImg
            height="150px"
            width="150px"
            className="application-image mb4-md"
            alt={testId}
          />
        : <div className="loading-application" data-testid="application-loading-state-image" />}
      {titleDiv}
    </div>
  );

  return (
    <div className={classNames("app-card", { 'app-card-disabled': !enabled })}>
      <div
        className="app-card-body ta-sm-c d-lg-flx flx-jc-lg-c"
        data-testid={testId}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        <Banner testId={testId} banner={banner} ts={ts} i18nString={i18nString} />
        <LinkWithDisable
          onClick={enabled ? handleClick : null}
          disabled={!enabled}
          to={slug}
        >
          {applicationBody}
        </LinkWithDisable>
      </div>
    </div>
  );
};

AppCard.propTypes = {
  openApplication: func,
  image: string,
  title: string,
  enabled: bool,
  testId: string,
  slug: string,
  authService: object,
  authState: object,
  banner: object,
  i18nString: func,
};

AppCard.defaultProps = {
  openApplication: null,
  image: '',
  title: '',
  route: null,
  enabled: false,
  testId: '',
  slug: '',
  banner: {},
  authService: null,
  authState: null,
  i18nString: null,
};

export default AppCard;

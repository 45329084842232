import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { bool, func, oneOf, string } from 'prop-types';

/**
 * A snackbar component to show the user information based on the actions they take
 * uses material ui snackbar
 * @param {string} horizontalOrigin - where to show the snackbar along the x axis, default is center
 * @param {string} verticalOrigin - where to show the snackbar along the y axis, default is bottom
 * @param {bool} open - if true show the snackbar
 * @param {func} handleClose - what happens when the snackbar is closed, should set open to false
 * @param {string} message - the message to show to the user
 * @param {string} type - controls the color and style of the snackbar, options are: error, info, success, warning
 */
const Snackbar = ({
  horizontalOrigin,
  verticalOrigin,
  open,
  handleClose,
  message,
  type,
  autoHide
}) => (
  <div data-testid="snackbar">
    <MuiSnackbar
      anchorOrigin={{
        vertical: verticalOrigin,
        horizontal: horizontalOrigin,
      }}
      open={open}
      autoHideDuration={autoHide ? 5000 : null}
      onClose={handleClose}
    >
      <Alert severity={type} variant="filled" aria-live="polite"> {message} </Alert>
    </MuiSnackbar>
  </div>
);

Snackbar.defaultProps = {
  horizontalOrigin: 'center',
  verticalOrigin: 'bottom',
  type: 'info',
  autoHide: true
};

Snackbar.propTypes = {
  horizontalOrigin: string,
  verticalOrigin: string,
  open: bool.isRequired,
  handleClose: func.isRequired,
  message: string.isRequired,
  type: oneOf(['error', 'info', 'success', 'warning']),
};

export default Snackbar;

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createRootReducer, { Actions } from '../modules/index';

export const history = createBrowserHistory();

const middlewares = [thunk, routerMiddleware(history)];

const composeEnhancers = composeWithDevTools({
  actionCreators: Actions,
  maxAge: 100,
  trace: true,
  traceLimit: 15,
});

const middleware = composeEnhancers(applyMiddleware(...middlewares));

export default function configureStore(initialState) {
  const store = createStore(
    createRootReducer(history), // adds the react-router state to our reducer
    initialState,
    middleware,
  );
  return store;
}

import React from 'react';
import { string } from 'prop-types';

/**
 * A Standard Nike Swoosh
 * @param {string} height - the height of the svg 
 * @param {string} fill - the color to fill the swoosh with 
 * @param {string} width - the width of the svg
 */
const Swoosh = ({ height, fill, width }) => {
  return (
    <div data-testid='swoosh'>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 69 32"
        height={height}
        width={width}
        fill={fill}
      >
        <path d="M68.56 4L18.4 25.36Q12.16 28 7.92 28q-4.8 0-6.96-3.36-1.36-2.16-.8-5.48t2.96-7.08q2-3.04 6.56-8-1.6 2.56-2.24 5.28-1.2 5.12 2.16 7.52Q11.2 18 14 18q2.24 0 5.04-.72z"/>
      </svg>
    </div>
  );
}

Swoosh.defaultProps = {
  height: '25px',
  witdh: '25px',
  fill: '#000000'
}

Swoosh.propTypes = {
  height: string,
  fill: string,
  width: string,
}

export default Swoosh;
export function nrPageAction(name, attr) {
  if(window.newrelic) {
    window.newrelic.addPageAction(name, attr);
  }
}

export function nrError(error) {
  if(window.newrelic) {
    window.newrelic.noticeError(error);
  } else {
    console.warn(error);
  }
}

export function nrSetAttribute(name, val) {
  if(window.newrelic) {
    window.newrelic.setCustomAttribute(name, val);
  }
}

export function nrInteraction() {
  if(window.newrelic) {
    return window.newrelic.interaction();
  }
  return null;
}
import { ApolloClient } from 'apollo-client';
import {createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getAccessToken, getAppId } from './modules/login';
import URLGenerator from './utils/urlGenerator';

// eslint-disable-next-line import/no-anonymous-default-export
export default (store) => {
  const customFetch = (uri, options) => fetch(`${URLGenerator.getRegionizedURL('graphql')}`, options);

  const httpLink = createHttpLink({
    fetch: customFetch,
  });

  const authLink = setContext((_, { headers }) => {
    const token = getAccessToken(store.getState());
    const appId = getAppId(store.getState());

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        appId,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};
import './HomeFloatingButton.css';
import { string, func, bool } from 'prop-types';
import Home from '../../assets/svgs/home';
import Tooltip from '@material-ui/core/Tooltip';


/**
 * A floating button
 * @param {function} action - what to do when the button is clicked
 * @param {string} testIdSuffix - will be stuck on the end of the test id to identify the button 
 * @param {boolean} [promptBeforeExit] - whether to prompt the user before exiting the application (defaults to false)
 * @param {string} [promptMessage] - message to display in the prompt if promptBeforeExit is true
 */
const HomeFloatingButton = ({ action, testIdSuffix, promptBeforeExit, promptMessage }) => {

  const onClick = (event) => {
    event.preventDefault();
    const proceed = promptBeforeExit
      ? !!window && !!window.confirm && window.confirm(promptMessage)
      : true;
    if (proceed && action) {
      window.document.title = 'Retail Launchpad';
      action();
    }
  };

  return (
    <div
      className="home-floating-button-left"
      onClick={onClick}
      data-testid={`home-floating-button-${testIdSuffix}`}
    >
      <Tooltip 
        className="home-floating-button-tooltip" 
        title="Retail Launchpad"  // not translating
        PopperProps={{
          popperOptions: {
            modifiers: {
              offset: {
                enabled: true,
                offset: '30px, -1px',
              },
            },
          },
        }}
      >
        <span>
          <Home className="home-icon" testId="home-icon-button" fill="black" />
        </span>
      </Tooltip>
    </div>
  )
}

HomeFloatingButton.defaultProps = {
  action: null,
  testIdSuffix: '',
  promptBeforeExit: false,
  promptMessage: '',
}

HomeFloatingButton.propTypes = {
  action: func,
  testIdSuffix: string,
  promptBeforeExit: bool,
  promptMessage: string,
}

export default HomeFloatingButton;
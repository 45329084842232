import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import localStorageService from '../services/localStorage';

/**
 * if there is not a valid auth token then kick the user to the login page
 * @param {object} children - whatever needs to be secure 
 */
const SecureRoute = ({children}) => {
  const { authState  } = useOktaAuth();
  const retailToken = localStorageService.getAuthToken();
 
  if(retailToken || authState.isAuthenticated){
    return children;
  }

  return <Redirect to="/login" />
}

export default SecureRoute;
import React, { useState, useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import RetailLogin from './retail/RetailLogin';
import OktaLogin from './OktaLogin';
import Snackbar from '../../components/Snackbar';
import Seperator from '../../components/Seperator';
import Swoosh from '../../assets/svgs/swoosh';
import ReactInfinity from '../../assets/img/ReactInfinityMedium.jpg';

import useInitialStoreValue from '../../hooks/useInitialStoreValue';
import { LoadingIndicator } from '../../assets/svgs/LoadingIndicator';

import ts from './login.i18n';

import './login.css';

/**
 * The login page, provides two options to login
 * retail employee login
 * okta login
 */
const Login = () => {
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { i18nString } = useContext(NikeI18nContext);
  const initialValues = useInitialStoreValue();

  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  }

  const handleOpenSnackbar = message => {
    setSnackbarMessage(message);
    setOpenSnackBar(true);
  }

  // wait for initial values
  if (initialValues === 'loading') {
    return (
    <div>
      <div data-testid="retail-login-loading-spinner" className="retail-login-loading-spinner">
        <LoadingIndicator height="100" width="100"></LoadingIndicator>
      </div>
    </div>
    );
  }

  return (
    <div 
      className="login-page" 
      data-testid="login-page"
      style={{
        backgroundImage: `url(${ReactInfinity})`,
        backgroundSize: "cover",
      }}
    >
      <Swoosh width="500px" height="250px" fill="#FFFFFF" />
      <h1 className="login-title">Retail Launchpad</h1>
      <div className="login-box">
        <RetailLogin openSnackbar={handleOpenSnackbar} defaultCountry={initialValues.country} defaultStoreNumber={initialValues.storeNumber} />
        <Seperator text={i18nString(ts.LOGIN_SEPERATOR)} />
        <OktaLogin />
      </div>
      <Snackbar
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        message={snackbarMessage}
        type="error"
        autoHide={false}
      />
    </div>
  )
};

export default Login;

export const oktaHostDev = 'nike-qa.oktapreview.com';
export const oktaHostProd = 'nike.okta.com';

export const oktaAuthServerIdDev = 'ausa0mcornpZLi0C40h7';
export const oktaAuthServerIdProd = 'aus27z7p76as9Dz0H1t7';

export const oktaHost = process.env.REACT_APP_BUILD_MODE === 'production'? oktaHostProd : oktaHostDev;
export const oktaAuthServerId = process.env.REACT_APP_BUILD_MODE === 'production' ? oktaAuthServerIdProd : oktaAuthServerIdDev;

// dev portal https://developer.niketech.com/apps/nike.grs.retail-unified-experience
export const OKTA_ISSUER = `https://${oktaHost}/oauth2/${oktaAuthServerId}`;
export const OKTA_CLIENT_ID = 'nike.grs.retail-unified-experience';
export const OKTA_HOST = oktaHost;
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';

/**
 * Checks to see if the user is authenticated
 * if authenticated send to applications page
 * if not authenticated send to login
 */
const AuthRedirect = () => {
  const { authState } = useOktaAuth();

  if (authState.isAuthenticated) {
    return <Redirect to="/applications" />;
  }
  
  return <Redirect to="/login" />;
};

export default AuthRedirect;
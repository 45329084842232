import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import useConstructor from './useConstructor';

function useInitialStoreValue() {
  const [country, setCountry] = useState(null);
  const [storeNumber, setStoreNumber] = useState(null);

  const location = useLocation();

  

  function getInitialStore() {
    // get store number from query parameter
    const query = qs.parse(location.search);
    // set store number to either query param, cached store number, or blank.
    const store = query.store || ' '; // || cacheAdapter.getPermanentCache('storeId') || '';
    const storeParts = store.split('-');
    if (storeParts.length === 2) {
      setCountry(storeParts[0]);
      setStoreNumber(storeParts[1]);
    } else {
      setCountry('');
      setStoreNumber('');
    }
  }
  useConstructor(getInitialStore);
  if (country === null || storeNumber === null) return 'loading';
  return {
    country,
    storeNumber,
  };
}

export default useInitialStoreValue;

import axios from 'axios';
import { proxyUrlMode as currentEnv } from '../setupEnvironment';

const env = currentEnv === 'prod' ? 'api' : 'api-preprod';

const fetchAllCountries = () => axios({
  method: 'GET',
  url: `https://${env}.nike.com/store/store_views/v2?search=(storeStatus==OPEN or storeStatus==UNOPENED or storeStatus==CLOSED)&fields=address.country&count=3000`,
  headers: {
    'nike-api-caller-id': 'com.nike:retail.sim',
  }
}).then(resp => {
  const data = resp?.data;
  const listOfCountries = [...new Set(data?.objects?.map(store => store?.address?.country))];
  listOfCountries.sort();
  const countryArray = listOfCountries?.map(country => ({
    value: country,
    label: country,
  }));
  return countryArray
});

export default fetchAllCountries;

import localStorageService from "../services/localStorage";

const REQUEST_ACCESS_TOKEN = 'requestAccessToken';
const SET_TITLE = 'setTitle';
const LOGOUT = 'logout';
const CLOSE = 'close';
const GET_LANGUAGE = 'getLanguage';
const GET_URL = 'getUrl'
const ADDRESS_BAR = 'addressbar';
const SEND_STORE = 'sendStore'

const RETAIL_AUTH = 'retail';
const OKTA_AUTH = 'okta';

export const Messages = {
  RequestAccessToken : REQUEST_ACCESS_TOKEN,
  SetTitle : SET_TITLE,
  Logout : LOGOUT,
  Close: CLOSE,
  GetLanguage: GET_LANGUAGE,
  GetURL: GET_URL,
  Addressbar: ADDRESS_BAR,
  SendStore: SEND_STORE
}

export const AuthTypes = {
  RetailAuth: RETAIL_AUTH,
  OktaAuth: OKTA_AUTH
}

const messageHandler = ({refreshAndSendToken, sendOktaToken, setTitle, logout, close, getLanguage, getParentUrl, updateAddressbar, sendStore}) => {
  return (
    (e) => {
      switch (e.data.name) {
        case Messages.RequestAccessToken:  
          if (localStorageService.getAuthSource() === AuthTypes.RetailAuth) {
            refreshAndSendToken(e);
          } else {
            sendOktaToken(e);
          }
          break;
         
        case Messages.SetTitle:  
          setTitle(e);
          break;

        case Messages.Logout: 
          logout(); 
          break;

        case Messages.Close:  
          close(e);
          break;
        
        case Messages.GetLanguage:
          getLanguage(e);
          break;
        
        case Messages.GetURL:
          getParentUrl(e);
          break;

        case Messages.Addressbar:
          updateAddressbar(e);
          break;  
        case Messages.SendStore:
          sendStore(e);
          break;
        default:
          break;
      }    
    }
  );
}

export default messageHandler;

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { NikeI18nContext } from '@nike/i18n-react';
import Link from '@material-ui/core/Link';
import { useOktaAuth } from '@okta/okta-react';
import { User, Swoosh, Home } from '../../assets/svgs/index';
import localStorageService from '../../services/localStorage';
import CacheAdapter from '../../adapters/CacheAdapter';

import ts from './header.i18n';
import './Header.css';

/**
 * The Header is located on the Applications Page
 */
const Header = () => {
  const { authService, authState } = useOktaAuth();
  const history = useHistory();
  const { i18nString } = useContext(NikeI18nContext);

  const logout = () => {
    const cacheAdapter = new CacheAdapter()
    
    localStorageService.logout();
    cacheAdapter.removeSessionCache('login')

    if(authState.isAuthenticated){
        authService.logout();
        authService.clearAuthState();
        cacheAdapter.removeSessionCache('okta-oauth-redirect-params')
        cacheAdapter.removeSessionCache('okta-pkce-storage')
    }

    history.push("/");
  };

  const options = [{
    label: i18nString(ts.DROPDOWN_SIGNOUT_OPTION),
    value: 'signOut',
    onClick: () => logout(),
  }];

  const closeClick = (event) => {
    event.preventDefault();
    window.document.title = 'Retail Launchpad'

    if (history.location.pathname !== '/applications') {
      history.goBack();
    }
  }

  const dropdownOptions = (
    <div className="accountMenu-dropdown-content" id="accountMenu-dropdown-list" data-testid="accountMenu-dropdown">
      <ul>
        <li className="account-option">{i18nString(ts.DROPDOWN_ACCOUNT_OPTION)}</li>
        {options.map(option => (
          <li key={option.value} data-testid={option.label} onClick={option.onClick}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="header-banner header-container" data-testid="header-container">
      <div className="ncss-row pt4-sm">
        <div className="header-home-icon-div">
          <Link href="#" onClick= {closeClick} data-testid="header-home-icon-link">
            <Home className="header-home-icon" data-testId="header-home-tab" fill="white" />
          </Link>
        </div> 
        <div className="swoosh-div">
          <div className="swoosh">
            <Swoosh width="60px" height="20px" fill="rgb(250,84,0)" />  
          </div>
          {/* not translating this value */}
          <h1 className="display-3">Retail Launchpad</h1>
        </div>
      </div>
      <div className="header-dropdown user-div d-lg-flx" data-testid="header-dropdown">
        <div className="circle" />
        <User className="profile-icon" testId="header-profile-tab" fill="white"/>
        {dropdownOptions}
      </div>
    </div>
  );
};

export default Header;

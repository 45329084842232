/**
 * This module contains newRelic actions.
 */

import { nrError } from "../utils/newRelic/nrBrowserApi";


// Action Types
const NEW_RELIC_ACTION = 'true/page/NEW_RELIC_ACTION';
const NEW_RELIC_ATTRIBUTE = 'true/page/NEW_RELIC_ATTRIBUTE';
const NEW_RELIC_ERROR = 'true/page/NEW_RELIC_ERROR';

export const Types = {
  NEW_RELIC_ACTION,
  NEW_RELIC_ATTRIBUTE,
  NEW_RELIC_ERROR,
};

// Actions
// const newRelicError = () => ({ type: NEW_RELIC_ERROR });
export const newRelicWindowError = () => ({ type: NEW_RELIC_ERROR });
export const newRelicError = (error) => (dispatch) => {
  if (!window.newrelic) {
    dispatch(newRelicWindowError());
    return Promise.resolve();
  }
  const errorObject = new Error(error);
  nrError(new Error(errorObject));
  console.log('newRelic-noticeError', errorObject);
};

export const newRelicAction = ({ name, attributes })  => (dispatch) => {
  if (!window.newrelic) {
    dispatch(newRelicWindowError());
    return Promise.resolve();
  }

  window.newrelic.addPageAction(name, attributes);
  console.log('newRelic-addPageAction', name, attributes);
};

export const newRelicAttribute = ({ name, value }) => (dispatch) => {
  if (!window.newrelic) {
    dispatch(newRelicWindowError());
    return Promise.resolve();
  }

  window.newrelic.setCustomAttribute(name, value);
  console.log('newRelic-setCustomAttribute', name, value)
};

export const Actions = {
  newRelicAction,
  newRelicAttribute,
  newRelicError,
};

import React, { useEffect, useState, useContext } from 'react';
import { func, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { NikeI18nContext } from '@nike/i18n-react';
import { connect } from 'react-redux';

import { LoadingIndicator } from '../../../assets/svgs/LoadingIndicator';
import Form from './Form';
import athleteAuthentication from '../../../services/athleteAuthentication';
import { Actions as loginActions } from '../../../modules/login';
import regionSetup from '../../../utils/regionSetup';
import localStorageService from '../../../services/localStorage';
import fetchAllCountries from '../../../utils/fetchAllCountries';
import fetchStoreNumbers from '../../../utils/fetchStoreNumbers';

import ts from '../login.i18n';
import { useNewRelic } from '../../../hooks/useNewRelic';


// TODO handle refresh on chicklet click
/**
 * The retail employee login form
 * @param {string} defaultCountry - the default country pulled from query string paramters
 * @param {string} defaultStoreNumber - the default store number pulled from query string paramters
 * @param {function} openSnackbar - when called will open the snackbar, will display given message
 * @param {function} loginRetailAuth - when called will set the login state for retail auth
 */
const RetailLogin = ({ defaultCountry, defaultStoreNumber, openSnackbar, loginRetailAuth }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const history = useHistory();
  const newRelicError = useNewRelic();
  const [employeeId, setEmployeeId] = useState('');
  const [password, setPassword] = useState('');
  const [country, setCountry] = useState(defaultCountry);
  const [storeNumber, setStoreNumber] = useState(defaultStoreNumber);
  const [countries, setCountries] = useState([]);
  const [storeNumbers, setStoreNumbers] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (setter) => (value) => {
    setter(value);
  };

  const getAllCountries = async () => {
    setLoading(true);
    const countries = await fetchAllCountries()
    setLoading(false);
    setCountries(countries);
  }
  
  const getStoreNumbers = async (updatedCountry) => {
    setLoading(true);
    const stores = await fetchStoreNumbers(updatedCountry);
    setLoading(false);
    setStoreNumbers(stores);
  }

  /**
   * sets the selected country to the one the user has selected, will also clear out the store number and store numbers state, since those are both based
   * off of what country is selected
   * will then fetch the new store numbers for the selected country
   * @param {string} country - the country the user has selected
   */
  const handleCountryChange = (country) => {
    setStoreNumber('');
    setStoreNumbers([]);
    setCountry(country);

    getStoreNumbers(country);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    regionSetup(`${country}-${parseInt(storeNumber, 10)}`);
    
    const athleteLoginInformation = {
      athleteNumber: employeeId,
      athletePassword: password,
      selectedCountry: country,
      selectedStore: storeNumber 
    }

    const athleteAuthResponse = await athleteAuthentication.login(athleteLoginInformation);
    if (!athleteAuthResponse.error) {
      loginRetailAuth(athleteAuthResponse);
      localStorageService.login(athleteAuthResponse.access_token, athleteAuthResponse.refresh_token, athleteAuthResponse.expires_in, undefined, 'retail');
      localStorageService.setStoreId(country, storeNumber);
      history.push('/applications');
    } else {
      openSnackbar(i18nString(ts.LOGIN_ERROR));
      newRelicError(i18nString(ts.LOGIN_ERROR));
    }

    setLoading(false);
  };

  useEffect(() =>{
    // first check cache
    let storeId = null;
    if (!defaultCountry && !defaultStoreNumber) {
      storeId = localStorageService.getStoreId();
    }
    
     // after checking cache, check if defaults were passed in
     if (defaultCountry && defaultStoreNumber) {
      storeId = { country: defaultCountry, storeNumber: defaultStoreNumber };
    }
    
    if (storeId && storeId.country && storeId.storeNumber) {
      setCountry(storeId.country);
      setStoreNumber(storeId.storeNumber);
    }
    setLoading(true);
    getAllCountries();

    return () => {
      setStoreNumber('');
      setStoreNumbers([]);
      setLoading(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getStoreNumbers(country);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])


  return (
    <div className="retail-login-container" data-testid="retail-login-container">
      { (loading) ? (
        <div data-testid="retail-login-loading-spinner" className="retail-login-loading-spinner">
          <LoadingIndicator height="100" width="100" />
        </div>
      ) : null }
      <Form
        countries={countries}
        storeNumbers={storeNumbers}
        employeeId={employeeId}
        password={password}
        country={country}
        storeNumber={storeNumber}
        setEmployeeId={handleChange(setEmployeeId)}
        setPassword={handleChange(setPassword)}
        setCountry={handleCountryChange}
        setStoreNumber={handleChange(setStoreNumber)}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

RetailLogin.defaultProps = {
  defaultCountry: '',
  defaultStoreNumber: '',
};

RetailLogin.propTypes = {
  defaultCountry: string,
  defaultStoreNumber: string,
  openSnackbar: func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  loginRetailAuth: loginActions.loginRetailAuth(dispatch),
});

export default connect(null, mapDispatchToProps)(RetailLogin);
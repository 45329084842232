import { string } from 'prop-types';
import React from 'react';

import './seperator.css';

/**
 * a vertical seperator
 * @param {string} text - text to display in the seperator 
 */
const Seperator = ({ text }) => (
  <div className='seperator' data-testid='seperator'>
    { text ? <p>{text}</p> : null }
  </div>
);

Seperator.defaultProps = {
  text: '',
};

Seperator.propTypes = {
  text: string,
}

export default Seperator;
import { combineReducers } from 'redux';
import applicationsState from './applications';
import { Actions as newRelicActions } from './newRelic';
import loginState, { Actions as loginActions }  from './login';
import languageState, { updateLanguage } from './language';

export const Actions = {
  loginActions,
  newRelicActions,
  updateLanguage
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => combineReducers({
  applicationsState,
  languageState,
  loginState,
});

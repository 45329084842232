import React, { useContext, useEffect, useMemo, useState } from 'react';
import { bool, func, object } from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { NikeI18nContext } from '@nike/i18n-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';
import uuid from 'react-uuid';
import ReactJson from 'react-json-view';
import AppCard from '../appCard/AppCard';
import Header from '../../components/header/Header';
import { LoadingIndicator } from '../../assets/svgs/LoadingIndicator';
import { Actions as loginActions, getIsLoggedIn } from '../../modules/login';
import { StoreConfigsContext } from '../../contexts/StoreConfigsProvider';
import NycFlagship from '../../assets/img/NycFlagship.jpeg';
import localStorageService from '../../services/localStorage';
import athleteAuthentication from '../../services/athleteAuthentication';

import {
  setLoadingAction,
  getLoading,
  getApplications,
  openApplicationAction,
  isProd,
} from '../../modules/applications';

import { getUserRoles, getAuthSource } from '../../modules/login';

import ts from './applications.i18n';
import './Applications.css';

/* Optional for development purposes, show user role details on screen */
const SHOW_DEBUG_INFO = false;

/**
 * loadingAppState - is the loading state for a single application
* @param {string} key - the key for the element since we
may return multiple elements
*/

const loadingAppState = (key) => (
  <div className="app-card-body" data-testid="application-loading-state-container" key={key}>
    <div className="loading-application" />
    <div className="loading-title" />
  </div>
);

/**
 * The Applications Page - lists all the applications avalible
 * to a certain user
 * @param {function} setLoading - handles updating the loading state of the applications
 * @param {boolean} isLoading - loading state
 * @param {object} apps - list of applications in data
 * @param {function} openApplication - opens the child application
 * @param {function} setLoginOktaAuth - re-setting the login state for an okta login
 * @param {function} setLoginRetailAuth - re-setting the login state for an retail login
 * @param {string} authSource - the source that the the user is loggging from
*/
const Applications = ({
  setLoading,
  isLoading,
  apps,
  openApplication,
  userRoles,
  setLoginOktaAuth,
  setLoginRetailAuth,
  authSource,
  isLoggedIn,
  isProd,
}) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { storeConfigs, storeConfigsLoading } = useContext(StoreConfigsContext);
  const { authState } = useOktaAuth();
  const [selfCheckoutDisabled, setSelfCheckoutDisabled] = useState(isProd ? true : false);
  const [simDisabled, setSimDisabled] = useState(isProd ? true : false);
  const [electronicJournalDisabled, setElectronicJournalDisabled] = useState(isProd ? true : false);
  const [storeOperationsReportingDisabled, setStoreOperationsReportingDisabled] = useState(isProd ? true : false);
  const [nikeCashDisabled, setNikeCashDisabled] = useState(true);
  const [storeHoursDisabled, setStoreHoursDisabled] = useState(true);
  const [athleteManagementDisabled, setAthleteManagementDisabled] = useState(false);
  const [storeConfigProcessedCount, setStoreConfigProcessedCount] = useState(0);
  const backgroundImage = NycFlagship;
  const isOktaAuthenticatedAndNotProd = authState.isAuthenticated && !isProd;
  const noAppsMessage = authSource === 'retail' ? ts.Store_Not_Configured : ts.User_Not_Authorized;

  useEffect(() => {
    if (storeConfigsLoading) return;
    if (storeConfigs) {
      const getConfigValue = (key) => {
        return (storeConfigs[key] === undefined) || storeConfigs[key].value;
      };

      setElectronicJournalDisabled(getConfigValue('disableEJ'));
      setNikeCashDisabled(getConfigValue('disableNikeCash'));
      setStoreHoursDisabled(getConfigValue('disableStoreHours'));
      setSelfCheckoutDisabled(getConfigValue('disableSelfCheckout'));
      setSimDisabled(getConfigValue('disableSIM'));
      setAthleteManagementDisabled(getConfigValue('disableAthleteManagement'));
      setStoreOperationsReportingDisabled(getConfigValue('disableSOR'));
      setStoreConfigProcessedCount((number) => number += 1);
    }
  }, [isProd, storeConfigsLoading, storeConfigs]);

  useEffect(() => {
    if (!isEmpty(apps) && isLoading) {
        setLoading(!isEmpty(apps) && isLoading);
    }
    if (isEmpty(apps)) {
        setLoading(true);
    }
  }, [isLoading, setLoading, apps]);

  useEffect(() => {
    const storeDetails = localStorageService.getStoreId();
    // need to check if there is cached login in the session storage
    const checkLogin = async () => {
      const userDetails = await athleteAuthentication.checkLogin();
      if (authSource === 'retail') {
        setLoginRetailAuth(userDetails, storeDetails, authSource);
      }
      if (authSource === 'okta') {
        setLoginOktaAuth(jwtDecode(authState.accessToken), storeDetails, authSource);
      }
    };
    // need to refresh the login state from the cache when routing to and from the iframe child app
    if (!userRoles && !isLoggedIn) {
      checkLogin();
    }
  }, [userRoles, authSource, authState.accessToken, isLoggedIn, setLoginOktaAuth, setLoginRetailAuth]);

  const listOfApps = useMemo(() => {
    if ((isLoading && isEmpty(apps))) {
      return (
        <div data-testid="applicationPage-loading-spinner" className="retail-login-loading-spinner" key="loading-apps">
          <LoadingIndicator fill="white" />
        </div>
      );
    }

    const appList = [];
    if ((isLoading && !isEmpty(apps))) {
      const items = Object.values(apps);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < items.length; i++) {
        if (items[i].display) {
          const app = loadingAppState(`${i}-application`);
          appList.push(app);
        }
      }
    }

    //use config service values to determine if app should be displayed
    const isDisabledInConfig = {
      selfCheckoutDeviceAssignment : selfCheckoutDisabled,
      electronicJournal:  electronicJournalDisabled,
      nikeCash:  nikeCashDisabled,
      storeHours: storeHoursDisabled,
      athleteManagement: athleteManagementDisabled,
      storeOperationsReporting: storeOperationsReportingDisabled,
      retailWebApp: simDisabled,
    }

    if (!isLoading && !isEmpty(apps)) {
      const authSource = localStorageService.getAuthSource();
        
      Object.keys(apps).forEach((key) => {
        const app = apps[key];

        if (isProd && (!app.display || isDisabledInConfig[key])) return;

        // only display app if auth source is compatible with app & userRoles are accepted
        if (app?.auth?.includes(authSource) && userRoles?.maxValue >= app.minimumRoleLevel) {
          const appElement = (
           <AppCard
              enabled={app.enabled}
              image={app.image}
              title={ts[app.title] && i18nString(ts[app.title])}
              testId={key}
              key={key || uuid()}
              openApplication={openApplication}
              slug={app.slug}
              banner={app?.banner}
              i18nString={i18nString}
            />
          );
          appList.push(appElement);
        }
      });
    }


    const displayAppList = appList.length > 0 ? appList : <h1 className="no-apps">{i18nString(noAppsMessage)}</h1>

    // only display apps if okta authenticated and not prod or storeConfig has been processed
    return (
      <div className="apps-grid">
        {isOktaAuthenticatedAndNotProd || storeConfigProcessedCount > 0 ? displayAppList : null}
      </div>
    );

  }, [isLoading, i18nString, apps, openApplication, userRoles, selfCheckoutDisabled, storeOperationsReportingDisabled, electronicJournalDisabled, nikeCashDisabled, storeHoursDisabled, athleteManagementDisabled, simDisabled, isOktaAuthenticatedAndNotProd, isProd, storeConfigProcessedCount, noAppsMessage]);

  const userRoleInfo =
    <div data-testid="applications-page-users-roles" style={{color: 'white', fontSize: '12px', padding: '50px 20px 120px 20px'}}>
      <div>Environment: {process.env.NODE_ENV}</div>
      <div>Build-Mode: {process.env.REACT_APP_BUILD_MODE ?? 'localhost'}</div>
      <div>Auth Source: {authSource}</div>
      <div>Logged-In: {`${isLoggedIn}`}</div>
      <div>Access Roles:<ReactJson theme='pop' style={{fontFamily:"inherit"}} collapsed src={userRoles}></ReactJson></div>
    </div>;

  return (
    <div data-testid="applications-page">
      <div className="page-background" style={{
        backgroundImage: `linear-gradient(0deg, rgba(84,84,84, 0.15), rgba(0,0,0,0.35)), url(${(backgroundImage)})`
      }} />
      <Header />
      {listOfApps}
      { process.env.NODE_ENV !== 'production' && SHOW_DEBUG_INFO ? userRoleInfo : null }
    </div>
  );
};

Applications.propTypes = {
  setLoading: func.isRequired,
  isLoading: bool.isRequired,
  apps: object.isRequired,
  openApplication: func.isRequired,
  isProd: bool.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: getLoading(state),
  apps: getApplications(state),
  userRoles: getUserRoles(state),
  authSource: getAuthSource(state),
  isLoggedIn: getIsLoggedIn(state),
  isProd: isProd(state),
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: bindActionCreators(setLoadingAction, dispatch),
  openApplication: bindActionCreators(openApplicationAction, dispatch),
  setLoginOktaAuth: loginActions.loginOktaAuth(dispatch),
  setLoginRetailAuth: loginActions.loginRetailAuth(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applications);

import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Security,  LoginCallback } from '@okta/okta-react';
import { OKTA_CLIENT_ID, OKTA_ISSUER } from './config/okta';

import { NikeDesignSystemProvider } from '@nike/nike-design-system-components';
import localStorageService from './services/localStorage';

import Applications from './containers/applications/Applications';
import Login from './containers/login/Login';
import AuthRedirect from './containers/login/AuthRedirect';
import WithNikeI18nProvider from './containers/language/WithNikeI18nProvider';
import SecureRoute from './components/SecureRoute';
import WindowMessageHandling from './components/WindowMessageHandling';
import ApplicationWrapper from './containers/applicationWrapper/ApplicationWrapper';
import StoreConfigsProvider from './contexts/StoreConfigsProvider';
import apps from './apps.json';
import uuid from 'react-uuid';
import envVariables from './setupEnvironment';
import { newRelicAction } from './modules/newRelic';

import './App.css';
import { getNcssLanguageCode } from './modules/language';

const applications = [];

Object.keys(apps).forEach((key) => {
  const route = (
    <Route path={`/${apps[key].slug}`} key={uuid()}>
      <SecureRoute>
        <ApplicationWrapper />
      </SecureRoute>
    </Route>
  );
  applications.push(route);
});

function App() {
  const ncssLanguageCode = useSelector(getNcssLanguageCode);

  // removes any existing okta cookies to force re-login
  const forceLogout = ()=>{
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    localStorageService.logout();
  }

  useEffect(() => {
    return () => { forceLogout (); }
  }, [])

  newRelicAction({
    name: "Enviroment Variables - TRUE",
    attributes: {
      environmentVariables: envVariables,
    },
  });

  useEffect(() => {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = `//www.nike.com/assets/ncss/3.0/dotcom/desktop/css/ncss.${ncssLanguageCode}.min.css`;

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    }
  }, [ncssLanguageCode]);

  return (
    <div className="App" data-testid="retail-unified-experience">
      <Router>
        <WithNikeI18nProvider>
          <NikeDesignSystemProvider
            lang={ncssLanguageCode}
          >
            <Security
              issuer={OKTA_ISSUER}
              clientId={OKTA_CLIENT_ID}
              redirectUri={`${window.location.origin}/auth/callback`}
              pkce
            >
              <WindowMessageHandling>
                <Route exact path="/" component={AuthRedirect}/>
                <Route path="/applications">
                  <SecureRoute>
                    <StoreConfigsProvider>
                      <Applications />
                    </StoreConfigsProvider>
                  </SecureRoute>
                </Route>
                {applications}
                <Route path="/login" component={Login} />
                <Route path="/auth/callback" component={LoginCallback} />
              </WindowMessageHandling>
            </Security>
          </NikeDesignSystemProvider>
        </WithNikeI18nProvider>
      </Router>
    </div>
  );
}

export default App;

import UrlConstants from './urlKeys';
import { proxyUrlMode } from '../setupEnvironment';

export default class UrlGenerator {
  static getRegionizedURL(key, dataRegion = global.SIM_REGION) {
    let url = UrlConstants[key];

    if (!url) {
      throw new Error(`Cannot find url with key "${key}"!`);
    }

    if (!dataRegion) dataRegion = 'us-east-1';
    let proxyRegion = dataRegion;
   /*  if (global.SIM_REGION !== dataRegion) {
      proxyRegion = global.SIM_REGION;
    } */

    // if country is south america, we need to explicitly force us-east-1 proxy until proxy can be deployed to sa-east-1
    if (global.SIM_REGION === 'sa-east-1') {
      proxyRegion = 'us-east-1';
    }

    if (proxyUrlMode === 'test') {
      proxyRegion = 'us-east-1';
      url = url.replace(/{proxyRegion}/g, proxyRegion);
      url = url.replace(/{dataRegion}/g, proxyRegion);
    } else {
      url = url.replace(/{proxyRegion}/g, proxyRegion);
      url = url.replace(/{dataRegion}/g, dataRegion);
    }

    return url;
  }

  static getNikeApiUrl() {
    if (proxyUrlMode === 'prod') {
      return 'https://api.nike.com/';
    }

    return 'https://api-test.nikecloud.com/';
  }
}

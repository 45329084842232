import React, { useState, createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { node, object } from 'prop-types';
import fetchStoreConfig from '../utils/fetchStoreConfig';

import { getAuthSource, getGroups } from '../modules/login';


export const StoreConfigsContext = createContext({});

export const getInitialStoreConfig = () => ({
  disableAthleteManagement: {key: 'disableAthleteManagement', value: true},
  disableCashManagement: {key: 'disableCashManagement', value: true},
  disableEJ: {key: 'disableEJ', value: true},
  disableNikeCash: {key: 'disableNikeCash', value: true},
  disableSIM: {key: 'disableSIM', value: true},
  disableSOR: {key: 'disableSOR', value: true},
  disableSelfCheckout: {key: 'disableSelfCheckout', value: true},
  disableStoreHours: {key: 'disableStoreHours', value: true}
});

export const getOktaStoreConfig = ({ groups, initialStoreConfig = getInitialStoreConfig() } = {}) => {
  return Array.isArray(groups)
  ? groups.reduce((currentStoreConfig, app) => {
    // Currently, Electronic Journal and SIM are the only apps that are setup for Okta login
    // Adding new Okta authorized applications will be done in the switch statement below
    // TODO:  Refactor to move the AD groups to apps.json file
    switch (app) {
        case 'App.ElectronicJournal.GLOBAL':
        case 'App.ElectronicJournal.GEO.APLA':
        case 'App.ElectronicJournal.ADMIN':
        case 'App.ElectronicJournal.EMEA':
        case 'App.ElectronicJournal.GEO.GC':
        case 'App.ElectronicJournal.GEO.NA':
          return { ...currentStoreConfig, disableEJ: { ...currentStoreConfig.disableEJ, value: false } };
        case 'Application.SIM.Web.Users':
          return { ...currentStoreConfig, disableSIM: { ...currentStoreConfig.disableSIM, value: false } };
        default:
          return currentStoreConfig;
      }
    }, initialStoreConfig)
  : groups;
};

export const getStoreConfig = async ({
  authSource,
  storeNumber,
  token,
  groups,
  getRetailStoreConfiguration = fetchStoreConfig,
  getOktaStoreConfiguration = getOktaStoreConfig,
} = {}) => {
  switch (authSource) {
    case 'retail':
      return await getRetailStoreConfiguration({ storeNumber, token });
    case 'okta':
      return getOktaStoreConfiguration({ groups });
    default:
      return null; 
  };
};

const StoreConfigsProvider = ({ children, mockedValue }) => {
  const authSource = useSelector(getAuthSource);
  const groups = useSelector(getGroups);
  const { Provider } = StoreConfigsContext;
  
  const country = localStorage.getItem('trueStoreId.country');
  const store = localStorage.getItem('trueStoreId.storeNumber');
  const storeNumber = `${country}-${store}`;
  
  const [storeConfigs, setStoreConfigs] = useState(null);
  const [storeConfigsLoading, setStoreConfigsLoading] = useState(false);

  useEffect(() => {
    const getStoreConfiguration = async () => {
      const token = JSON.parse(sessionStorage.getItem('login'));
    
      setStoreConfigsLoading(true);
      const storeConfiguration = await getStoreConfig({ authSource, storeNumber, token, groups });
      setStoreConfigs(storeConfiguration);
      setStoreConfigsLoading(false);
    };

    getStoreConfiguration();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeNumber]);

  return (
    <Provider
      value={mockedValue ?? {
        storeConfigs,
        storeConfigsLoading
      }}
    >
      {children}
    </Provider>
  );
};

StoreConfigsProvider.defaultProps = {
  children: {},
  mockedValue: null,
};

StoreConfigsProvider.propTypes = {
  children: node,
  mockedValue: object
};

export default StoreConfigsProvider;
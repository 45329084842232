const translations = {
  LOGIN_TITLE: {
    description: 'title of the application on the login page',
    primaryValue: 'The Retail Unified Experience',
    stringKey: 'loginTitle',
  },
  LOGIN_SEPERATOR: {
    description: 'is shown between the two login options (retail athlete and okta) to show the user can pick either one',
    primaryValue: 'or',
    stringKey: 'loginSeperator',
  },
  LOGIN_ERROR: {
    description: 'error message shown when login returns an error',
    primaryValue: 'Invalid Employee Number or Password',
    stringKey: 'loginError',
  },
  LOGIN_OKTA_BUTTON: {
    description: 'sign in button for okta login',
    primaryValue: 'Okta Login',
    stringKey: 'loginOktaButton',
  },
  LOGIN_EMPLOYEE_ID_PLACEHOLDER: {
    description: 'placeholder text in the input for employee id on the login page',
    primaryValue: 'employee ID',
    stringKey: 'loginEmployeeIdPlaceholder',
  },
  LOGIN_PASSWORD_PLACEHOLDER: {
    description: 'placeholder text in the input for password on the login page',
    primaryValue: 'password',
    stringKey: 'loginPasswordPlaceholder',
  },
  LOGIN_COUNTRY_LABEL: {
    description: 'label for the country input on the login page',
    primaryValue: 'Country',
    stringKey: 'loginCountryLabel',
  },
  LOGIN_STORE_NUMBER_LABEL: {
    description: 'label for the store number input on the login page',
    primaryValue: 'Store Number',
    stringKey: 'loginStoreNumberLabel',
  },
  LOGIN_RETAIL_LOGIN_BUTTON: {
    description: 'Retail Login Button on login page',
    primaryValue: 'Retail Login',
    stringKey: 'retailLogin',
  },
};

module.exports = translations;

const authObjectName = 'trueRetailAuth';

const localStorageService = {
  login: (accessToken, refreshToken, tokenTimeout, prn, source) => {
    const authObject = {
      accessToken,
      refreshToken,
      tokenTimeout,
      prn,
    };
    localStorage.setItem(authObjectName, JSON.stringify(authObject));
    if (source) localStorage.setItem('trueAuthSource', source);
    sessionStorage.activeSession = true;
  },
  refreshRetailToken: (accessToken, refreshToken, tokenTimeout) => {
    const authObjectString = localStorage.getItem(authObjectName);
    if (authObjectString) {
      const authObject = JSON.parse(authObjectString);
      authObject.accessToken = accessToken;
      authObject.refreshToken = refreshToken;
      authObject.tokenTimeout = tokenTimeout
      localStorage.setItem(authObjectName, JSON.stringify(authObject));
    }
  },
  logout: () => {
    localStorage.removeItem(authObjectName);
    localStorage.removeItem('trueAuthSource');
    localStorage.removeItem('okta-cache-storage');
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-oauth-redirect-params');
    localStorage.removeItem('okta-pkce-storage');
    sessionStorage.activeSession = false;
  },

  getAuthToken: () => {
    const authObjectString = localStorage.getItem(authObjectName);
    if (authObjectString) {
      const authObject = JSON.parse(authObjectString);
      if (authObject.accessToken) return authObject.accessToken;
      return null;
    }
    return null;
  },
  getOktaAuth: () => {
    return localStorage.getItem('okta-token-storage');
  },
  getAuthSource: () => {
    return localStorage.getItem('trueAuthSource');
  },
  getRefreshToken: () => {
    const authObjectString = localStorage.getItem(authObjectName);
    if (authObjectString) {
      const authObject = JSON.parse(authObjectString);
      if (authObject.refreshToken) return authObject.refreshToken;
      return null;
    }
    return null;
  },
  getTokenTimeout: () => {
    const authObjectString = localStorage.getItem(authObjectName);
    if (authObjectString) {
      const authObject = JSON.parse(authObjectString);
      if (authObject.tokenTimeout) return authObject.tokenTimeout;
      return null;
    }
    return null;
  },
  getStoreId: () => {
    const storeId = {
      country: localStorage.getItem('trueStoreId.country'),
      storeNumber: localStorage.getItem('trueStoreId.storeNumber'),
      imageUrl: localStorage.getItem('trueStoreId.imageUrl'),
    };
    return storeId;
  },
  setStoreId: (country, storeNumber, imageUrl) => {
    localStorage.setItem('trueStoreId.country', country);
    localStorage.setItem('trueStoreId.storeNumber', storeNumber);
    localStorage.setItem('trueStoreId.imageUrl', imageUrl);  //  Confirm if trueStoreId.imageUrl is not being used and delete if not in use
  },
  getLanguage: () => {
    const language = {
      i18nLanguageCode: localStorage.getItem('rlLanguage.i18nLanguageCode'),
      ncssLanguageCode: localStorage.getItem('rlLanguage.ncssLanguageCode'),
    };
    return language;
  },
  setLanguage: (i18nLanguageCode, ncssLanguageCode) => {
    localStorage.setItem('rlLanguage.i18nLanguageCode', i18nLanguageCode);
    localStorage.setItem('rlLanguage.ncssLanguageCode', ncssLanguageCode);
  },
}

export default localStorageService;
import axios from 'axios';
import CacheAdapter from '../adapters/CacheAdapter';
import { GET_ATHLETE_AUTH_URL } from '../config/athleteAuthentication';
import { nrError } from '../utils/newRelic/nrBrowserApi';

const cacheAdapter = new CacheAdapter();
const currentEnv = process.env.REACT_APP_BUILD_MODE === 'production' ? 'prod' : 'test';

const handleLogin = async (loginInformation, refresh = false) => {
  const postBody = { loginInformation, refresh };
  try {
    const authResponse = await axios({
      method: 'POST',
      url: GET_ATHLETE_AUTH_URL(currentEnv),
      data: postBody,
      headers: {
        'content-type': 'application/json',
      }
    });
    const { data } = authResponse;
    const expiresAt = Date.now() + data.expires_in * 1000;
    cacheAdapter.setSessionCache('login', data, expiresAt);
    return { ...data, expires_at: expiresAt };
  } catch (error) {
    nrError(new Error(error));
    return {
      error: true,
      errorMessage: error.message
    }
  }
};

const athleteAuthentication = {
  login: (athleteLoginInfo) => handleLogin(athleteLoginInfo),
  refresh: (tokens) => handleLogin(tokens, true),
  checkLogin: () => Promise.resolve(cacheAdapter.getSessionCache('login'))
};

export default athleteAuthentication;

import sortBy from 'lodash/sortBy';

export const UserFOHRoles = {
  GeneralFOH: 10,
  Cashier: 20,
  CustomerService: 30,
  Admin: 40,
  AssistantManagerFOH: 50,
  ManagerFOH: 60,
  SupportFOH: 99,
};

export const UserBOHRoles = {
  GeneralBOH: 10,
  ShippingReceivingAssociate: 20,
  InventoryAdjuster: 30,
  KeyHolder: 40,
  AssistantManagerBOH: 50,
  ManagerBOH: 60,
  SupportBOH: 99,
};

// NOTE: Unique Report UserRoles:
// ProductRefillMarkAllAsFilled = 40,
// StorePerformance = 40,
// Adjustments = 30,

/**
 * Maping the userRoles to values
 * @param {array} roles - array of string role values
 * @param {object} enumerator - the value associated with a userRole
 * @returns an array of objects for userRoles with the name and set value
 * example: {name: "GeneralFOH", value: 10}
 */
const mapRolesToDataType = (roles, enumerator)=> {
  const unsorted = roles.map((roleStr) => {
    const roleVal = enumerator[roleStr] || null;

    if (!roleVal) {
      return null;
    }

    return { name: roleStr, value: roleVal };
  }).filter(Boolean);

  return sortBy(unsorted, ['value']);
};

/**
 * Maping the userRoles to values
 * @param {array} roles - array of string role values
 * @returns an object of userRoles with set values and max values
 */
export const parseUserRoles = roles => {
  if (!roles || !roles.map || typeof roles !== 'object') { return null; }
  const fohRoles = mapRolesToDataType(roles, UserFOHRoles);
  const bohRoles = mapRolesToDataType(roles, UserBOHRoles);
  const highestBOHRole = bohRoles.slice(-1)[0];
  const highestFOHRole = fohRoles.slice(-1)[0];
  const bohMaxValue = highestBOHRole ? highestBOHRole.value : 0;
  const fohMaxValue = highestFOHRole ? highestFOHRole.value : 0;
  const allRoles = [...fohRoles, ...bohRoles];

  return {
    bohRoles,
    fohRoles,
    bohMaxValue,
    fohMaxValue,
    allRoles,
    maxValue: Math.max(bohMaxValue, fohMaxValue),
  };
};

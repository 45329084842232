import React, { useContext, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import { NikeI18nContext } from '@nike/i18n-react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import localStorageService from '../../services/localStorage';

import Button from '../../components/Button';
import { Actions as loginActions } from '../../modules/login';

import ts from './login.i18n';

/**
 * The coorporate or employee w/ a nike email login form
 * The okta login button, okta react handles the actual redirect
 * @param {function} loginOktaAuth - when called will set the login state for retail auth
 */
const OktaLogin = ({ loginOktaAuth }) => {
  const { authState, authService } = useOktaAuth();
  const { i18nString } = useContext(NikeI18nContext);

  useEffect(() => {
    if (authState.isAuthenticated === true) {
      localStorage.setItem('trueAuthSource', 'okta');
      const decodedToken = jwtDecode(authState.accessToken);
      const storeDetails = localStorageService.getStoreId();
      const authSource = localStorageService.getAuthSource();
  
      loginOktaAuth(decodedToken, storeDetails, authSource);
    }
  }, [authState, loginOktaAuth]);

  const login = () => {
    authService.login('/applications');
  };

  if (authState.isAuthenticated === true) {
    return <Redirect to="/applications" />;
  }

  return (
    <div>
      <Button
        onClick={() => login()}
        buttonText={i18nString(ts.LOGIN_OKTA_BUTTON)}
        testId="okta-login-button"
      />
    </div>
  );
};

export const mapDispatchToProps = dispatch => ({
  loginOktaAuth: loginActions.loginOktaAuth(dispatch),
});

export default connect(null, mapDispatchToProps)(OktaLogin);
//  TODO update list after Bodega adds new languages to project

const languageCodes = {
  'ar': { ncss: 'ar-ae', i18n: 'ar' },  // Arabic
  'ca': { ncss: 'ca-es', i18n: 'ca' },  // Catalan
  'cs': { ncss: 'cs-cz', i18n: 'cs' },  // Czech
  'da': { ncss: 'da-dk', i18n: 'da' },  // Danish
  'de': { ncss: 'de-de', i18n: 'de' },  // German
  'de-DE': { ncss: 'de-de', i18n: 'de'},  // German
  'el': { ncss: 'el-gr', i18n: 'el' },  // Greek
  'en': { ncss: 'en-us', i18n: 'en' },  // English (US)
  'en-ca': { ncss: 'en-ca', i18n: 'en' },  // English (US)
  'en-GB': { ncss: 'en-gb', i18n: 'en-GB' },  // Engligh (GB)
  'en-US': { ncss: 'en-us', i18n: 'en' },  // English (US)
  'es-419': { ncss: 'es-es', i18n: 'es-419' },  // Spanish (419)
  'es-AR': { ncss: 'es-ar', i18n: 'es-ES' },  // Spanish (Argentina)
  'es-CL': { ncss: 'es-cl', i18n: 'es-ES' },  // Spanish (Argentina)
  'es-ES': { ncss: 'es-es', i18n: 'es-ES' },  // Spanish (Spain)
  'es-MX': { ncss: 'es-mx', i18n: 'es-MX' },  // Spanish (Mexico)
  'es-UY': { ncss: 'es-uy', i18n: 'es-ES' },  // Spanish (Spain)
  'fr': { ncss: 'fr-fr', i18n: 'fr' },  // French
  'hr': { ncss: 'hr-hr', i18n: 'hr' },  // Croatian
  'he': { ncss: 'he-he', i18n: 'he' },  // Hebrew
  'hu': { ncss: 'hu-hu', i18n: 'hu' },  // Hungarian
  'it': { ncss: 'it-it', i18n: 'it' },  // Italian
  'ja': { ncss: 'ja-jp', i18n: 'ja' },  // Japanese
  'ko': { ncss: 'ko-kr', i18n: 'ko' },  // Korean
  'nb': { ncss: 'no-no', i18n: 'nb' },  // Norwegian (Bokmål)
  'nl-nl': { ncss: 'nl-nl', i18n: 'nl' },  // Dutch
  'pl': { ncss: 'pl-pl', i18n: 'pl' },  // Polish
  'pt-BR': { ncss: 'pt-br', i18n: 'pt-BR' },  // Portuguese (Brazil)
  'pt-PT': { ncss: 'pt-pt', i18n: 'pt-PT' },  // Portuguese (Portugal)
  'ru': { ncss: 'ru-ru', i18n: 'ru' },  // Russian
  'sv': { ncss: 'sv-se', i18n: 'sv'},  // Swedish
  'th': { ncss: 'th-th', i18n: 'th'},  // Thai
  'tr': { ncss: 'tr-tr', i18n: 'tr' },  // Turkish
  'zh-CN': { ncss: 'zh-cn', i18n: 'zh-Hans' },  // Chinese (Simplified)
  'zh-TW': { ncss: 'zh-tw', i18n: 'zh-Hant' }  // Chinese (Traditional)
};

export default languageCodes
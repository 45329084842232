import axios from 'axios';
import env from '../setupEnvironment';

const fetchStoreConfig = ({ storeNumber, token }) => axios({
  method: 'GET',
  url: `https://deledge.${env.proxyUrlMode}.commerce.nikecloud.com/store/configs/v2/store/${storeNumber}`,
  headers: {
    Authorization: `Bearer ${token?.value?.access_token}`
  }
}).then(resp => {
  const values = {};
  resp.data.objects.forEach((configValue) => {
  
  const splitKey = configValue.key.split('.');
  const groupName = splitKey[0];
  if (groupName === 'true') {
    const keyName = splitKey.slice(1).join('.');
    if (!values[keyName]) { values[keyName] = {}; }
    values[keyName] = {
      key: keyName,
      value: configValue.value,
      scope: configValue.scopeType,
    };
  }});
  return values;
});

export default fetchStoreConfig;

import React , { useRef, useEffect } from 'react';
import { string  } from 'prop-types';
import uuid from 'react-uuid';
import './iframe.css';

/**
 * Will show whatever is returned from the given url in an iframe
 * @param {string} source - the url to show in the iframe
 * @param {string} title - the title of the iframe
 */
const IFrame = ({ source, title, i18nLanguage, ncssLanguage }) => {
  const iframeRef = useRef();
  
  useEffect(() => {
    const current = iframeRef.current;
    // eslint-disable-next-line no-self-assign
    if (current) {
      // current.src = current.src;
      current.style.opacity = 1;
    }
  }, []);

  const uuidValue = uuid();

  return (
  <iframe
    id={title}
    title={title}
    key={uuidValue}
    src={`${source}`}
    data-testid={`iframe-${title}`}
    ref={iframeRef}
    i18n={i18nLanguage}
    ncss={ncssLanguage}
    sandbox='allow-same-origin allow-scripts allow-top-navigation allow-modals allow-downloads'
  />
)};

IFrame.propTypes = {
  source: string,
  title: string,
};

IFrame.defaultProps = {
  source: '',
  title: '',
};

export default IFrame;

import { get } from 'lodash';
import { Actions as NewRelicActions } from '../modules/newRelic.js';
import { parseUserRoles } from '../utils/parseUserRoles.js';
import CacheAdapter from '../adapters/CacheAdapter';

// Action Types
const RETAIL_LOGIN = 'true/login/RETAIL_LOGIN';
const RETAIL_LOGOUT = 'true/login/RETAIL_LOGOUT';
const OKTA_LOGIN = 'true/login/OKTA_LOGIN';

export const Types = {
  OKTA_LOGIN,
  RETAIL_LOGIN,
  RETAIL_LOGOUT,
};

export const userRoles = {
  allRoles: [
    { name: "GeneralFOH", value: 10 },
    { name: "Cashier", value: 20 },
    { name: "CustomerService", value: 30 },
    { name: "Admin", value: 40 },
    { name: "GeneralBOH", value: 10 },
    { name: "ShippingReceivingAssociate", value: 20 },
    { name: "InventoryAdjuster", value: 30 },
    { name: "KeyHolder", value: 40 },
  ],
  bohMaxValue: 40,
  bohRoles: [
    { name: "GeneralBOH", value: 10 },
    { name: "ShippingReceivingAssociate", value: 20 },
    { name: "InventoryAdjuster", value: 30 },
    { name: "KeyHolder", value: 40 },
  ],
  fohMaxValue: 40,
  fohRoles: [
    { name: "GeneralFOH", value: 10 },
    { name: "Cashier", value: 20 },
    { name: "CustomerService", value: 30 },
    { name: "Admin", value: 40 },
  ],
  maxValue: 40,
};

const cacheAdapter = new CacheAdapter();

// Getters
const localState = state => state.loginState;

/**
 * @param {object} state - the current redux state
 */
export const getCountry = state => get(localState(state), ['country'], null);
export const getUserRoles = state => get(localState(state), ['userRoles'], []);
export const getStore = state => get(localState(state), ['store'], null);
export const getIsLoggedIn = state => get(localState(state), ['isLoggedIn'], false);
export const getAuthSource = state => get(localState(state), ['authSource'], null);
export const getAppId = state => get(localState(state), ['appId'], '');
export const getAccessToken = state => get(localState(state), ['userDetails', 'access_token'], null);
export const getGroups = state => get(localState(state), ['groups'], null);

export const Getters = {
  getCountry,
  getUserRoles,
  getStore,
  getIsLoggedIn,
  getAppId,
  getAccessToken,
};

// Actions
const loginRetailAuth = (dispatch) => tokenDetails => {
  if(!tokenDetails) {
    cacheAdapter.removePermanentCache('trueRetailAuth');

    dispatch({
      type: RETAIL_LOGOUT,
      payload: {}
    });

    return;
  }

  const { roles, given_name, family_name, prn } = tokenDetails;
  const [country, storeNumber, user] = prn.split(';');

  const payload = {
    userDetails: tokenDetails,
    country,
    storeNumber,
    store: `${country}-${storeNumber}`,
    userRoles: parseUserRoles(roles),
    givenName: given_name,
    familyName: family_name,
    user,
    authSource: 'retail'
  };

  dispatch({
    type: RETAIL_LOGIN,
    payload
  });
  // Set custom new relic attributes
  dispatch(NewRelicActions.newRelicAttribute({ name: 'store-number', value: parseInt(storeNumber, 10).toString() }));
  dispatch(NewRelicActions.newRelicAttribute({ name: 'store-country', value: country }));
  dispatch(NewRelicActions.newRelicAttribute({ name: 'store-country-number', value: `${country}-${parseInt(storeNumber, 10)}` }));
  dispatch(NewRelicActions.newRelicAttribute({ name: 'user', value: user }));
  dispatch(NewRelicActions.newRelicAttribute({ name: 'authSource', value: 'retail' }));
  dispatch(NewRelicActions.newRelicAction({
    name: 'retail-login',
    attributes: {
      user,
      storeId: `${country}-${parseInt(storeNumber, 10)}`,
      country,
    },
  }));

  sessionStorage.setItem('activeSession', true);
  return Promise.resolve();
};

const loginOktaAuth = (dispatch) => (tokenDetails, storeDetails, authSource) => {
  const { country, storeNumber } = storeDetails;
  const { sub, groups } = tokenDetails;

  const payload = {
    country,
    storeNumber,
    store: `${country}-${storeNumber}`,
    groups,
    userRoles: groups && userRoles,
    user: sub,
    authSource,
  };

  dispatch({
    type: OKTA_LOGIN,
    payload,
  });

  cacheAdapter.setSessionCache('login', payload);

  // Set custom new relic attributes
  dispatch(NewRelicActions.newRelicAttribute({ name: 'store-number', value: parseInt(storeNumber, 10).toString() }));
  dispatch(NewRelicActions.newRelicAttribute({ name: 'store-country', value: country }));
  dispatch(NewRelicActions.newRelicAttribute({ name: 'store-country-number', value: `${country}-${parseInt(storeNumber, 10)}` }));
  dispatch(NewRelicActions.newRelicAttribute({ name: 'user', value: sub }));
  dispatch(NewRelicActions.newRelicAttribute({ name: 'authSource', value: authSource }));
  dispatch(NewRelicActions.newRelicAction({
    name: 'okta-login',
    attributes: {
      sub,
      storeId: `${country}-${parseInt(storeNumber, 10)}`,
      country,
    },
  }));
  sessionStorage.setItem('activeSession', true);
  return Promise.resolve();
};

export const Actions = {
  loginRetailAuth,
  loginOktaAuth,
};

// Reducer
export const initialState = {
  isLoggedIn: false,
  userDetails: null,
  country: null, // USA
  store: null, // USA-128
  storeNumber: null, // 128
  givenName: null, // john
  familyName: null, // smith
  user: null, // 99999 or john.smith@nike.com
  fetching: false,
  error: null,
  userRoles: null,
  authSource: 'retail', // retail, okta
  appId: 'nike.athlete.platform'
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case RETAIL_LOGIN: {
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
      }
    }
    case RETAIL_LOGOUT:{
      return {  
        ...initialState,
      }
    }
    case OKTA_LOGIN: {
      const { user } = action.payload;
      const splitUser = user?.split('@');
      const [givenName, familyName] = splitUser[0].split('.');
      return {
        ...state,
        ...action.payload,
        givenName,
        familyName,
        isLoggedIn: true,
      }
    }
      default:
        return state;
    }
}

// import React from 'react';
import { ReactComponent as retailWeb } from './retailWeb.svg';
import { ReactComponent as electronicJournal } from './electronicJournal.svg';
import { ReactComponent as template } from './template.svg';
import { ReactComponent as selfCheckout } from './selfCheckoutIcon.svg';
import { ReactComponent as nikeCash } from './nikeCash.svg';
import { ReactComponent as athleteManagement } from './athleteManagement.svg';
import { ReactComponent as storeOperationsReporting } from './storeOperationsReporting.svg'
import { ReactComponent as storeHours } from './storeHours.svg'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  retailWeb,
  electronicJournal,
  template,
  selfCheckout,
  nikeCash,
  storeOperationsReporting,
  athleteManagement,
  storeHours
};

import { useState } from 'react';

/**
 * Will only run the given callback once
 * @param {function} callback - the function to be called
 */
const useConstructor = (callback = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callback();
  setHasBeenCalled(true);
};

export default useConstructor;

export const getI18nLanguageCode = ({ languageState }) => languageState.i18n;
export const getNcssLanguageCode = ({ languageState }) => languageState.ncss;

export const updateLanguage = language => ({
  type: 'UPDATE_LANGUAGE',
  payload: language
});

const initialState = { ncss: 'en-us', i18n: 'en' };

export default function languageReducer( state = initialState, { type, payload } = {} ) {
  switch (type) {
    case updateLanguage().type:
      return payload;
    default:
      return state;
  };
};
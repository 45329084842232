import React, { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { NikeI18nContext } from '@nike/i18n-react';
import match, { Default } from '../../utils/match';
import IFrame from '../../components/iframe/Iframe';
import HomeButton from '../../components/floatingButton/HomeFloatingButton';
import { getOpenApplications, closeApplicationActionCreator } from '../../modules/applications';
import { buildMode } from '../../setupEnvironment';
import apps from '../../apps.json';
import './applicationWrapper.css';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { getI18nLanguageCode, getNcssLanguageCode } from '../../modules/language';
import localStorageService from '../../services/localStorage';

import ts from './applicationWrapper.i18n';

export const getSource = ({ buildMode, country, appConfig }) => {
  const { chinaProd, chinaTest, develop, prod, test } = appConfig.urls;
  const { isChinaConfig } = appConfig;
  const isEnv = (env) => ({ buildMode }) => buildMode === env;
  const isChinaEnv = (env) => ({ buildMode, country, isChinaConfig }) => (
    country === 'CHN' &&
    isEnv(env)({ buildMode }) &&
    isChinaConfig
  );

  return match({ buildMode, country, isChinaConfig })(
    [isChinaEnv('production'), chinaProd],
    [isChinaEnv('test'), chinaTest],
    [isEnv('production'), prod],
    [isEnv('test'), test],
    [Default, develop],
  );
};

export const getDisplayIframe = ({ country, appConfig, source }) => {
  const { isChinaConfig } = appConfig;
  const isChina = country => country === 'CHN';
  const isNotChina = ({ country }) => country !== 'CHN';
  const isChinaAndConfigured = ({ country, isChinaConfig, source }) => (
    isChina(country) &&
    isChinaConfig && 
    source.length > 0
  );

  return match({ country, isChinaConfig, source })(
    [isChinaAndConfigured, true],
    [isNotChina, true],
    [Default, false],
  );
};

const ApplicationWrapper = ({ closeApplication, openApplications }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const { country } = localStorageService.getStoreId()
  const ncssLanguageCode = useSelector(getNcssLanguageCode);
  const i18nLanguageCode = useSelector(getI18nLanguageCode);
  if (openApplications.length === 0) {
    return  <Redirect to="/applications" />;
  }

  // TO DO - this is not the end solution but a works for now solution
  // The solution will be to intercept the back button on the browser and add in the logic that exists within the home button
  // eslint-disable-next-line no-restricted-globals
  history.pushState(null, null, location.href);
    window.onpopstate = function () {
        // eslint-disable-next-line no-restricted-globals
        history.go(1);
    };

  return (
    <div>
      <div className="true-child-app-container" data-testid="application-wrapper">
        {
          openApplications.map((application) => {
            const details = apps[application];

            const handleCloseApplication = () => {
              closeApplication(application);
            };

            const source = getSource({ buildMode, country, appConfig: details });
            const displayIframe = getDisplayIframe({ country, appConfig: details, source });
              
            return (
              <div className="true-iframe-container" key={details.title} data-testid={`iframe-${application}`}>
                <div className="home-button">
                  <HomeButton
                    testIdSuffix={application}
                    action={handleCloseApplication}
                    promptBeforeExit={details?.promptBeforeExit}
                    promptMessage={i18nString(ts.Before_Exit_Prompt_Message)}
                  />
                </div>
                {displayIframe ? (
                <div className="i-frame">
                  <IFrame
                    id={details.title}
                    title={details.title}
                    source={`${source}?cb=${new Date().getTime() + Math.floor(Math.random() * 1000000)}`}
                    ncssLanguage={ncssLanguageCode}
                    i18nLanguage={i18nLanguageCode}
                  />
                </div>
                ) : (
                  <div className='headling-1 no-access'>
                    {i18nString(ts.App_Not_Configured)}<br />
                    {i18nString(ts.Click_Home_Button)}
                  </div>
                )}
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  openApplications: getOpenApplications(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeApplication: bindActionCreators(closeApplicationActionCreator, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationWrapper);

import updatedTranslations from '../translations';

export const supportedLanguages = [
  {
    name: 'English (US)',
    tag: 'en',
  },
  {
    name: 'Czech',
    tag: 'cs',
  },
  {
    name: 'English (GB)',
    tag: 'en-GB',
  },
  {
    name: 'German',
    tag: 'de',
  },
  {
    name: 'Greek',
    tag: 'el',
  },
  {
    name: 'Spanish (Spain)',
    tag: 'es-ES',
  },
  {
    name: 'Spanish (Mexico)',
    tag: 'es-MX',
  },
  {
    name: 'French',
    tag: 'fr',
  },
  {
    name: 'Hebrew',
    tag: 'he',
  },
  {
    name: 'Croatian',
    tag: 'hr',
  },
  {
    name: 'Hungarian',
    tag: 'hu',
  },
  {
    name: 'Italian',
    tag: 'it',
  },
  {
    name: 'Japanese',
    tag: 'ja',
  },
  {
    name: 'Korean',
    tag: 'ko',
  },
  {
    name: 'Dutch',
    tag: 'nl',
  },
  {
    name: 'Polish',
    tag: 'pl',
  },
  {
    name: 'Portuguese (Brazil)',
    tag: 'pt-BR',
  },
  {
    name: 'Portuguese (Portugal)',
    tag: 'pt-PT',
  },
  {
    name: 'Russian',
    tag: 'ru',
  },
  {
    name: 'Turkish',
    tag: 'tr',
  },
  {
    name: 'Chinese (Simplified)',
    tag: 'zh-Hans',
  },
  {
    name: 'Chinese (Traditional)',
    tag: 'zh-Hant',
  },
];

export default updatedTranslations;

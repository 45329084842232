import { proxyUrlMode as mode } from '../setupEnvironment';

const proxyBaseUrl = `https://nginx-proxy.exp-${mode}-{proxyRegion}.nde-sim-web-${mode}.nikecloud.com`;

const graphqlBaseUrl = process.env.REACT_APP_GRAPHQL_URL
  || `https://simweb-{proxyRegion}.simweb-${mode}.nikecloud.com`;

const UrlKeys = {
  apiNike: `${proxyBaseUrl}/${mode}/apiNike/`,
  deledge: `${proxyBaseUrl}/${mode}/deledge/{dataRegion}/`,
  uphService: `${proxyBaseUrl}/${mode}/uph/{dataRegion}/`,
  graphql: `${graphqlBaseUrl}/graphql`,
  workforce: `${proxyBaseUrl}/${mode}/workforce/`,
};

export default UrlKeys;

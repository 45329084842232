import { isUndefined } from "lodash";
import languageCodes from "../../languageCodes";

const getLanguage = (language = 'en') => {
  if (isUndefined(languageCodes[language])) {
    return languageCodes['en'];
  }
  return languageCodes[language];
};

export default getLanguage;

import pkg from '../package.json';

export const buildMode = process.env.REACT_APP_BUILD_MODE || 'none';
export const nodeEnv = process.env.NODE_ENV;
export const gitHash = process.env.REACT_APP_GIT_HASH || 'local';
export const branch = process.env.REACT_APP_BRANCH || 'no_branch';
export const version = `v${pkg.version}-${branch}-${gitHash}`;

let mode = 'test';
let badge = '';

if (nodeEnv === 'production') {
  mode = 'prod';
  badge = '';

  if (buildMode === 'preview') {
    badge = 'Preview';
  }

  if (buildMode === 'test') {
    mode = 'test';
    badge = 'Test';
  }
}

if (nodeEnv === 'development') {
  badge = 'Dev';

  if (buildMode ==='test') {
    mode = 'test'
  }

  if (buildMode === 'preview' || buildMode === 'production') {
    mode = 'prod';
  }
}

if (nodeEnv === 'test') {
  mode = 'test';
  badge = 'Unit Tests';
}

export const proxyUrlMode = mode; // needs to be set to prod for local development, why?
export const navigationBadge = badge;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  branch,
  buildMode,
  gitHash,
  navigationBadge,
  nodeEnv,
  proxyUrlMode,
  version,
};

import React from 'react';
import { string, func, object } from 'prop-types';

import './Banner.css';

const Banner =({ testId, banner, ts, i18nString })=>{
  const bannerEnabled = banner?.display ?? false;
  const bannerText = ts && banner?.i18nKey && ts[banner.i18nKey]? i18nString(ts[banner?.i18nKey]) : '';

  return (
    <div className="banner" data-testid={`banner-${testId}`}>
      <div className="banner-container" data-testid={`banner-trapezoid-${testId}`}>
        {bannerEnabled ? <div className="banner-text" data-testid={`banner-text-${testId}`}>{bannerText}</div> : null}
      </div>     
    </div>
  );
}

Banner.propTypes = {
  testId: string,
  banner: object,
  ts: object,
  i18nString: func,
};

Banner.defaultProps = {
  testId: '',
  banner: {},
  ts: {},
  i18nString: null,
};

export default Banner;
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NikeI18nProvider } from '@nike/i18n-react';
import updatedTranslations, { supportedLanguages } from '../../utils/translations.js';
import localStorageService from '../../services/localStorage.js';
import { isProd } from '../../modules/applications.js';

import { getI18nLanguageCode, getNcssLanguageCode, updateLanguage } from '../../modules/language';
import getLanguage from './getLanguage.js';

const localLanguage = navigator.language;

function WithNikeI18nProvider({ children }) {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const paramsLanguage = !useSelector(isProd) && searchParams.get('lang');

  const dispatch = useDispatch();
  const languageTag = useSelector(getI18nLanguageCode);
  localStorageService.setLanguage(languageTag, useSelector(getNcssLanguageCode));

  useEffect(() => {
    dispatch(updateLanguage(getLanguage(paramsLanguage || localLanguage)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <NikeI18nProvider
      currentLanguageTag={languageTag}
      supportedLanguages={supportedLanguages}
      translations={updatedTranslations}
    >
      {children}
    </NikeI18nProvider>
  );
}

export default WithNikeI18nProvider;

const translations = {
  Store_Inventory_Management: {
    description: 'App Launcher Page - chicklet title for the Store Inventory Management application',
    primaryValue: 'Store Inventory Management',
    stringKey: 'storeInventoryManagement',
  },
  Self_Checkout_Device_Assignment: {
    description: 'App Launcher Page - Self Checkout Device application',
    primaryValue: 'Kiosk Self-Checkout',
    stringKey: 'selfCheckoutDevice',
  },
  Electronic_Journal: {
    description: 'App Launcher Page - chicklet title for the Electronic Journal application',
    primaryValue: 'Electronic Journal',
    stringKey: 'electronicJournal',
  },
  Store_Hours: {
    description: 'App Launcher Page - chicklet title for the Store Hours application',
    primaryValue: 'StoreHours',
    stringKey: 'storeHours',
  },
  Application_Template: {
    description: 'App Launcher Page - chicklet title for the SIM form localhost',
    primaryValue: 'Template Application',
    stringKey: 'simLocal',
  },
  Nike_Cash: {
    description: 'App Launcher Page - chicklet title for the Nike CASH application',
    primaryValue: 'Nike CASH',
    stringKey: 'nikeCash',
  },
  Store_Operations_Reporting: {
    description: 'App Launcher Page - chicklet title for the Store Operations Reporting',
    primaryValue: 'Store Operations Reporting',
    stringKey: 'storeOperationsReporting',
  },
  Athlete_Management: {
    description:
      "App Launcher Page - chicklet title for the Athlete Management application",
    primaryValue: "Athlete Management",
    stringKey: "Athelete_Management",
  },
  No_Access: {
    description: 'Message informing user of lack of access',
    primaryValue: 'You do not have access to this platform, please contact your administrator.',
    stringKey: 'true.noAccess',
  },
  Coming_Soon: {
    description: 'Banner message for chicklets - Coming Soon',
    primaryValue: 'Coming Soon!',
    stringKey: 'true.banner.comingSoon'
  },
  New: {
    description: 'Banner message for chicklets - New',
    primaryValue: 'New!',
    stringKey: 'true.banner.new'
  },
  User_Not_Authorized: {
    description: 'Message informing user is not authorized to access apps',
    primaryValue: 'This user is not authorized for any Retail Launchpad apps.  Please contact store operations lead to get access',
    stringKey: 'userNotAuthorized'
  },
  Store_Not_Configured: {
    description: 'Message informing user that there are no apps configured for the store',
    primaryValue: 'No apps configured for the store, please reach out to store operations lead to get apps configured',
    stringKey: 'storeNotConfigured'
  }
};

module.exports = translations;

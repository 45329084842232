import React, { useContext } from 'react';
import { array, func, string } from 'prop-types';
import { DropDownStyled } from '@nike/nike-design-system-components';
import { NikeI18nContext } from '@nike/i18n-react';

import Button from '../../../components/Button';

import ts from '../login.i18n';

import './retailLogin.css';

const Form = ({
  countries, storeNumbers, employeeId, password, country, storeNumber, setEmployeeId, setPassword, setCountry, setStoreNumber, handleSubmit,
}) => {
  const { i18nString } = useContext(NikeI18nContext);

  return (
    <form onSubmit={handleSubmit} className="retail-login-form" data-testid='retail-login-form'>
        <input 
          data-testid="retail-login-form-username-input"
          type="text"
          placeholder={i18nString(ts.LOGIN_EMPLOYEE_ID_PLACEHOLDER)}
          aria-label={i18nString(ts.LOGIN_EMPLOYEE_ID_PLACEHOLDER)}
          onChange={(event) => {setEmployeeId(event.target.value) }}
          name="employeeLogin"
          autoComplete="username"
          value={employeeId}
          required
        />
        <input
          data-testid="retail-login-form-password-input"
          type="password"
          placeholder={i18nString(ts.LOGIN_PASSWORD_PLACEHOLDER)}
          aria-label={i18nString(ts.LOGIN_PASSWORD_PLACEHOLDER)}
          onChange={(event) => { setPassword(event.target.value) }}
          name="password"
          autoComplete="current-password"
          value={password}
          required
        />
        <div className='store-id-box' data-testid="retail-login-form-store-country-input">
          <DropDownStyled
            name={"countryDropdown"}
            id={"retail-login-country-dropdown"}
            label={i18nString(ts.LOGIN_COUNTRY_LABEL)}
            options={countries}
            value={country}
            onChange={(event) => {
              setCountry(event.target.value)}
            }
            SelectProps={{
              required: true,
              "data-testid": "retail-login-country-select",
            }}
          />
          <DropDownStyled
            name={"storeNumberDropdown"}
            id={"retail-login-store-number-dropdown"}
            label={i18nString(ts.LOGIN_STORE_NUMBER_LABEL)}
            options={storeNumbers}
            value={storeNumber}
            onChange={(event) => setStoreNumber(event.target.value)}
            SelectProps={{
              required: true,
              "data-testid": "retail-login-store-number-select",
            }}
          />
        </div>
        <Button
          buttonText={i18nString(ts.LOGIN_RETAIL_LOGIN_BUTTON)}
          type="submit"
          testId="retail-login-submit-button"
        />
      </form>
  );
}
Form.defaultProps = {
  countries: [],
  storeNumbers: []
};

Form.propTypes = {
  countries: array,
  storeNumbers: array,
  employeeId: string.isRequired,
  password: string.isRequired,
  country: string.isRequired,
  storeNumber: string.isRequired,
  setEmployeeId: func.isRequired,
  setPassword: func.isRequired,
  setCountry: func.isRequired,
  setStoreNumber: func.isRequired,
  handleSubmit: func.isRequired,
};

export default Form;

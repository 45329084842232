const translations = {
  DROPDOWN_ACCOUNT_OPTION: {
    description: 'Application header - User profile dropdown account option',
    primaryValue: 'Account',
    stringKey: 'account',
  },
  DROPDOWN_SIGNOUT_OPTION: {
    description: 'Application header - User profile dropdown sign-out option',
    primaryValue: 'Sign Out',
    stringKey: 'signOut',
  },
};

module.exports = translations;

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Actions as NewRelicActions } from "../modules/newRelic";

export const useNewRelic = () => {
  const dispatch = useDispatch();

  return useCallback(
    (error) => {
      dispatch(NewRelicActions.newRelicError(error))
    },
    [dispatch]
  );
};
import { useOktaAuth } from '@okta/okta-react';
import uuid from 'react-uuid';
import localStorageService from '../services/localStorage.js';
import athleteAuthentication from '../services/athleteAuthentication';
import messageHandler, { AuthTypes } from '../utils/iframeMessageHandling';
import apps from '../apps.json'
import { nrError } from '../utils/newRelic/nrBrowserApi.js';

const appEntries = Object.entries(apps);

/**
 * determines if this is a valid origin
 * @param {string} origin event origin url
 */
export const isValidOrigin = (origin) => {
  let combinedArray = []
  const urls = appEntries.map(app => Object.values(app[1].urls));
  urls.forEach(url => combinedArray.push(...url));
  return combinedArray.find(x => x.includes(origin)) !== undefined;
}

/**
 * gets the language of the parent window
 * @param {object} e event
 */
export const getLanguage = (e) =>{
  const returnObject = {
    name: 'langLocale',
    isLanguage: true,
    i18nLanguageCode: localStorage.getItem('rlLanguage.i18nLanguageCode'),
    ncssLanguageCode: localStorage.getItem('rlLanguage.ncssLanguageCode'),
  };

  if (isValidOrigin(e.origin)) {
    e.source.window.postMessage(returnObject, e.origin); // NOSONAR above line validates the origin
  }
  else {
    throw new Error("Invalid origin passed, ", e.origin)
  }
}

/**
 * gets the location of the parent window
 * @param {object} e event
 */
export const getParentUrl = (e) =>{
  const returnObject = {
    url: window.location.href,
  };

  if (isValidOrigin(e.origin)) {
    e.source.window.postMessage(returnObject, e.origin); // NOSONAR above line validates the origin
  }
  else {
    throw new Error("Invalid origin passed, ", e.origin)
  }
}

/**
 * Sends the store info to SIMWEB in order to update the state properly
 * @param {*} e event
 */
export const sendStore = (e) => {
  const returnObject = {
    storeNumber: localStorage.getItem('trueStoreId.storeNumber'),
    country: localStorage.getItem('trueStoreId.country'),
  };

  if (isValidOrigin(e.origin)) {
    e.source.window.postMessage(returnObject, e.origin); // NOSONAR above line validates the origin
  }
  else {
    throw new Error("Invalid origin passed, ", e.origin)
  }
}

/**
 * refreshes the underlying retail athlete auth token from local storage
 * if the token errors, forces a logout.
 * @param {object} e event
 */
export const refreshAndSendToken = async (e) => {
  const tokenBody = {
    access_token: localStorageService.getAuthToken(),
    refresh_token: localStorageService.getRefreshToken()
  }
  const tokens = await athleteAuthentication.refresh(tokenBody, true);
  // if refresh fails then send the user to the login page
  if (tokens.error) {
    localStorageService.logout();
    nrError(new Error(tokens.error));
    return;
  }

  const returnObject = {
    name: 'accessToken',
    token: { accessToken: tokens.access_token, refreshToken: tokens.refresh_token },
    authType: AuthTypes.RetailAuth,
  };

  if (isValidOrigin(e.origin)) {
    e.source.window.postMessage(returnObject, e.origin); // NOSONAR above line validates the origin
    localStorageService.refreshRetailToken(tokens.access_token, tokens.refresh_token, tokens.expiresAt);
  }
  else {
    nrError(new Error(e.origin));
    throw new Error("Invalid origin passed, ", e.origin)
  }
}

/**
 * sends the okta token to the client from local stoarage.
 * @param {object} e event
 */
export const sendOktaToken = (e) => {
  const returnObject = {
    name: 'accessToken',
    token: localStorageService.getOktaAuth(),
    authType: AuthTypes.OktaAuth,
  };


  if (isValidOrigin(e.origin)) {
    e.source.window.postMessage(returnObject, e.origin); // NOSONAR above line validates the origin
  }
  else {
    throw new Error("Invalid origin passed, ", e.origin)
  }
}

/**
 * sets the title of the application on browser tab.
 * @param {object} e event
 */
export const setTitle = (e)=>{
  window.document.title = e.data.title;
}

/**
 * clears localstorage and calls authService on logout.
 * @param {object} authService okta authServices
 */
export const logout = (authService) => {
  localStorageService.logout();
  authService.logout('');
};

/**
 * redirects the page to chicklet landing page
 * @param {e} event
 */
export const close = (e) => {
  window.location.assign(`${window.location.origin}/applications`);
}

/**
 * updates the window addressbar
 * @param {e} event
 */
export const updateAddressbar = (e) => {
  window.history.replaceState({}, '', e.data.newurl);
}

/**
 * wraps the children components with window message handling, allows child applications to request
 * things from this application
 * @param {object} children
 */
const WindowMessageHandling = ({ children }) => {
  const { authService } = useOktaAuth();

  const trueLogout = (e) => {
    logout(authService);
  }

 window.onmessage = messageHandler({refreshAndSendToken, sendOktaToken, setTitle, logout: trueLogout, close, getLanguage, getParentUrl, updateAddressbar, sendStore });

  return (
    children.map(child =>
      <div key={uuid()}>{child}</div>
    )
  );
}

export default WindowMessageHandling;

import React from 'react';
import {
  bool, func, string, object, oneOfType,
} from 'prop-types';
import { ButtonStyled } from '@nike/nike-design-system-components';

const Button = ({
  onClick,
  buttonText,
  extraClasses,
  disabled = false,
  testId,
  ...rest
}) => (
  <div
    className={`button ${
      disabled ? 'disabled' : ''
    } ${extraClasses}`}
  >
    <ButtonStyled
      type="button"
      className="unified-button"
      data-testid={testId}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {buttonText}
    </ButtonStyled>
  </div>
);

Button.propTypes = {
  disabled: bool,
  onClick: func,
  buttonText: oneOfType([string, object]),
  extraClasses: string,
  testId: string,
};

export default Button;
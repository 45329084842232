export default class CacheAdapter {
  setSessionCache(key, value = null, expiration = null) {
    return this.setCache(key, value, expiration, sessionStorage);
  }

  getSessionCache(key) {
    return this.getCache(key, sessionStorage);
  }

  removeSessionCache(key) {
    return this.removeCache(key, sessionStorage);
  }

  clearSessionCache() {
    return this.clearCache(sessionStorage);
  }

  setPermanentCache(key, value = null, expiration = null) {
    return this.setCache(key, value, expiration, localStorage);
  }

  getPermanentCache(key) {
    return this.getCache(key, localStorage);
  }

  removePermanentCache(key) {
    return this.removeCache(key, localStorage);
  }

  clearPermanentCache() {
    return this.clearCache(localStorage);
  }

  setCache(key, value, expiration, storage) {
    if (!value && value !== 0) {
      return null;
    }

    let exp = null;
    if (expiration) {
      exp = Date.now() + expiration;
    }

    const data = {
      value,
      exp,
    };

    storage.setItem(key, JSON.stringify(data));
    return value;
  }

  getCache(key, storage) {
    const dataStr = storage.getItem(key);

    if (!dataStr) { return null; }

    try {
      const data = JSON.parse(dataStr);
      if (data.exp && data.exp <= Date.now()) {
        return null;
      }
      return data.value;
    } catch (e) {
      return null;
    }
  }

  removeCache(key, storage) {
    storage.removeItem(key);
  }

  clearCache(storage) {
    storage.clear();
  }
}
